<template>
  <div>
    <!--begin::prontuario-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
            <h3 style="float: left" class="textTitile mt-4 mb-4">
              Informações pessoais
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="atualizarPaciente">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome completo"
                  rules="required"
                >
                  <v-text-field
                    v-model="nomeCompleto"
                    label="Nome completo (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>

                <v-text-field
                  v-model="nomeSocial"
                  label="Nome social (Opcional)"
                  :error-messages="errors"
                  required
                ></v-text-field>

                <validation-provider
                  v-slot="{ errors }"
                  name="Nome do pai"
                  rules="required"
                >
                  <v-text-field
                    v-model="nomePai"
                    label="Nome do pai (Opcional)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Nome da mãe "
                  rules="required"
                >
                  <v-text-field
                    v-model="nomeMae"
                    label="Nome da mãe (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>

                <div class="row">
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="UBS"
                      rules="required"
                    >
                      <v-text-field
                        v-model="ubs"
                        label="UBS (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Cartão SUS "
                      rules="required"
                    >
                      <v-text-field
                        v-model="sus"
                        label="Cartão SUS (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="RG"
                      rules="required"
                    >
                      <v-text-field
                        v-model="rg"
                        label="RG (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="CPF"
                      rules="required"
                    >
                      <v-text-field
                        v-model="cpf"
                        label="CPF (Obrigatório)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3 mt-4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Estado civil"
                      rules="required"
                    >
                      <b-form-select
                        style="border-color: black"
                        v-model="estadoCivil"
                        :error-messages="errors"
                        :options="options"
                        required
                      ></b-form-select>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Telefone"
                      rules="required"
                    >
                      <v-text-field
                        v-model="telefone"
                        label="Telefone (Obrigatório)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Data de nascimento"
                      rules="required"
                    >
                      <v-text-field
                        type="date"
                        v-model="dataNascimento"
                        label="Data de nascimento (Obrigatório)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Município"
                      rules="required"
                    >
                      <v-text-field
                        v-model="municipio"
                        label="Município (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Rua"
                      rules="required"
                    >
                      <v-text-field
                        v-model="rua"
                        label="Rua (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Número"
                      rules="required"
                    >
                      <v-text-field
                        v-model="numero"
                        label="numero (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Bairro"
                      rules="required"
                    >
                      <v-text-field
                        v-model="bairro"
                        label="bairro (Opcional)"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>

                <v-btn
                  style="float: right"
                  @click="atualizarPaciente"
                  :disabled="invalid"
                  class="textStyle"
                  tile
                  color="#1dd1a1"
                >
                  <span style="color: white">Atualizar</span>
                </v-btn>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});
export default {
  name: "prontuario",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    dados: Object
  },
  async created() {
    try {
      console.log("Dados pessoas ");
      console.log(this.dados);

      const { data } = await ambulatorio.indexPaciente(this.dados.paciente_id);
      this.nomeCompleto = data.pessoa.nome;
      this.nomeSocial = data.nome_social;
      this.nomeMae = data.nome_mae;
      this.nomePai = data.nome_pai;
      this.telefone = data.telefone;
      this.ubs = data.ubs;
      this.sus = data.sus;
      this.prontuario = data.prontuario;
      this.prioridade = data.prioridade;
      this.estadoCivil = data.estado_civil_id;
      this.rua = data.endereco.rua;
      this.numero = data.endereco.numero;
      this.bairro = data.endereco.bairro;
      this.municipio = data.endereco.municipio;
      this.cpf = data.pessoa.cpf;
      this.rg = data.pessoa.rg;
      this.dataNascimento = data.pessoa.data_nascimento;
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      nomeSocial: "",
      nomeMae: "",
      nomePai: "",
      telefone: "",
      ubs: "",
      sus: "",
      prontuario: "",
      prioridade: "false",
      estadoCivil: "",
      rua: "",
      numero: "",
      bairro: "",
      municipio: "",
      nomeCompleto: "",
      dataNascimento: "",
      rg: "",
      cpf: "",
      sexoId: "1",
      options: [
        { value: null, text: "Selecione um estado civil (Obrigatório)" },
        { value: 1, text: "Solteiro(a)" },
        { value: 2, text: "Casado(a)" },
        { value: 3, text: "Divorciado(a)" },
        { value: 4, text: "Viúvo(a)" }
      ]
    };
  },

  methods: {
    atualizarPaciente: async function() {
      var data = {
        paciente: {
          nome_social: this.nomeSocial,
          nome_mae: this.nomeMae,
          nome_pai: this.nomePai,
          telefone: this.telefone,
          ubs: this.ubs,
          sus: this.sus,
          prontuario: this.prontuario,
          prioridade: this.prioridade,
          estado_civil_id: this.estadoCivil,
          endereco_attributes: {
            rua: this.rua,
            numero: this.numero,
            bairro: this.bairro,
            municipio: this.municipio
          },
          pessoa_attributes: {
            nome: this.nomeCompleto,
            data_nascimento: this.dataNascimento,
            rg: this.rg,
            cpf: this.cpf,
            sexo_id: "1"
          }
        }
      };

      try {
        await ambulatorio.atualizarPaciente(data, this.dados.paciente_id);

        Swal.fire({
          title: "",
          text: "Paciente atualizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário" }]);
  }
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}
</style>
