<template>
  <div class="mb-4">
    <!--begin::Dashboard-->

    <b-modal
      ref="my-modal"
      hide-footer
      centered
      id="modal-triagem"
      title="Dados da consulta e triagem"
    >
      <div>
        <VisualizarDadosConsulta :idConsulta="this.idConsulta" />
      </div>
    </b-modal>

    <b-modal
      ref="filter-modal"
      hide-footer
      centered
      id="modal-data"
      title="Filtrar consulta por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
        <h3 style="float: left" class="textTitile mt-4 mb-4">
          Histórico de Consultas
        </h3>
      </div>
      <div class="col-md-12 mt-4">
        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              v-b-tooltip.hover.top="'Pesquisar'"
              @click="consultaResultadoconsultas"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultaResultadoconsultas"
            v-model="consulta"
            class="mt-14"
            label="Pesquise consultas por Data, CPF, especialidade"
            color="green"
            filled
            solo-inverted
          ></v-text-field>

          <v-btn
            v-b-modal.modal-data
            v-b-tooltip.hover.top="'Filtrar por intervalo'"
            class="ml-4 mb-2 mt-6"
            color="#1dd1a1"
          >
            <span style="color: white"></span>
            <v-icon center small color="#FFF"
              >fas fa-calendar-alt</v-icon
            ></v-btn
          >
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>

            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>
            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="
                  this.page == this.totalPages || this.totalPages == 0
                    ? true
                    : false
                "
                @click="proximoPage"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Paciente</th>
                <th class="">CPF</th>
                <th class="">Data da consulta</th>
                <th class="">Atendido por</th>
                <th class="">Contato</th>
                <th class="">Especialidade</th>
                <th class="">Prontuário</th>
                <th class="">Opções</th>
              </tr>
            </thead>

            <tbody v-for="consulta in tempConsultas" :key="consulta.id">
              <tr>
                <td>
                  {{ consulta.dados_paciente.pessoa.nome }}
                </td>
                <td>
                  {{ consulta.dados_paciente.pessoa.cpf }}
                </td>
                <td>
                  {{ consulta.data }}
                </td>
                <td>
                  {{ consulta.medico_nome }}
                </td>
                <td>
                  {{ consulta.dados_paciente.telefone }}
                </td>

                <td>
                  {{ consulta.especialidade.descricao }}
                </td>
                <td>
                  {{ consulta.prontuario.numero }}
                </td>
                <td>
                  <b-button
                    @click="sendValorToModel(consulta.id)"
                    variant="success"
                    v-b-tooltip.hover.top="'Visualizar dados da consulta'"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                    v-b-modal.modal-triagem
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-file-medical</v-icon
                    ></b-button
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempConsultas.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import * as ambulatorio from "../../../../core/services/ambulatorioAPI.service";
import VisualizarDadosConsulta from "./VisualizarDadosConsulta.vue";

export default {
  name: "HistoricoConsultas",
  props: {
    dados: Object,
  },
  async created() {
    try {
      const { data } = await ambulatorio.listHistoricoConsulta(
        this.dados.dados_prontuario.id,
        1
      );

      this.consultas = data.historico_consulta;
      this.tempConsultas = data.historico_consulta;
      this.totalPages = data.total_pages;

      console.log("CONSULTAS REALIZADAS AHHHHHHHHHHH");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      consultaDataUm: "",
      consultaDataDois: "",
      idConsulta: "",
      descricao: "",
      dataResultado: "",
      dataSolicitacao: "",
      totalPages: 0,
      page: 1,
      consultas: [],
      tempConsultas: [],
      consulta: "",
    };
  },

  methods: {
    consultaResultadoconsultas: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.listHistoricoConsulta(
          this.dados.dados_prontuario.id,
          1
        );

        this.consultas = data.historico_consulta;
        this.tempConsultas = data.historico_consulta;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            created_at: this.consulta,
            "pessoas.nome": this.consulta,
            "prontuarios.numero": this.consulta,
            "pessoas.cpf": this.consulta,
            "especialidades.descricao": this.consulta,
          },

          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaHistoricoConsulta(data);

        this.consultas = response.data.resultado;
        this.tempConsultas = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },

    hideModal() {
      this.$refs["filter-modal"].hide();
    },

    consultarPorIntervalo: async function () {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf,
          },
          required: {
            "especialidades.id": "",
            created_at: [this.consultaDataUm, this.consultaDataDois],
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaHistoricoConsulta(data);

        this.consultas = response.data.resultado;
        this.tempConsultas = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal();
      } catch (error) {
        console.log(error);
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const { data } = await ambulatorio.listHistoricoConsulta(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.consultas = data.historico_consulta;
          this.tempConsultas = data.historico_consulta;
          this.totalPages = data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const { data } = await ambulatorio.listHistoricoConsulta(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.consultas = data.historico_consulta;
          this.tempConsultas = data.historico_consulta;
          this.totalPages = data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    sendValorToModel: function (id) {
      this.idConsulta = id;
    },
  },
  components: { VisualizarDadosConsulta },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
