<template>
  <div>
    <b-modal
      ref="filter-modal"
      hide-footer
      centered
      id="modal-dataTriagem"
      title="Filtrar triagem por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <div class="mb-4">
      <!--begin::Dashboard-->
      <div class="row">
        <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
          <h3 style="float: left" class="textTitile mt-4 mb-4">
            Histórico de Triagens
          </h3>
        </div>
        <div class="col-md-12 mt-4">
          <v-toolbar style="height: 6rem">
            <v-toolbar-title
              ><v-btn
                @click="consultaResultadoTriagens"
                color="#1dd1a1"
                class="mr-4 mb-2 mt-6"
              >
                <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
              ></v-toolbar-title
            >

            <v-text-field
              @keyup.enter.native="consultaResultadoTriagens"
              v-model="consulta"
              class="mt-14"
              label="Pesquise triagem por data"
              color="green"
              filled
              solo-inverted
            ></v-text-field>

            <v-btn
              v-b-modal.modal-dataTriagem
              v-b-tooltip.hover.top="'Filtrar por intervalo'"
              class="ml-4 mb-2 mt-6"
              color="#1dd1a1"
            >
              <span style="color: white"></span>
              <v-icon center small color="#FFF"
                >fas fa-calendar-alt</v-icon
              ></v-btn
            >
          </v-toolbar>

          <div class="row mt-6">
            <div
              style="
                display: flex;
                align-itens: center;
                justify-content: space-between;
              "
              class="col-md-12"
            >
              <b-tooltip :target="`tooltip3-target`" triggers="hover">
                Página anterior
              </b-tooltip>
              <b-tooltip :target="`tooltip4-target`" triggers="hover">
                Próxima página
              </b-tooltip>
              <div class="mt-2">
                <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
              </div>
              <div>
                <v-btn
                  :disabled="this.page == 0 || this.page == 1 ? true : false"
                  @click="anteriorPage"
                  id="tooltip3-target"
                  color="#1dd1a1"
                  class="mr-1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-left</v-icon
                  ></v-btn
                >
                <v-btn
                  :disabled="
                    this.page == this.totalPages || this.totalPages == 0
                      ? true
                      : false
                  "
                  @click="proximoPage"
                  id="tooltip4-target"
                  color="#1dd1a1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-right</v-icon
                  ></v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Data da triagem</th>
                  <th class="">Temperatura</th>
                  <th class="">Peso</th>
                  <th class="">Altura</th>
                  <th class="">Frequẽncia cardíaca</th>
                  <th class="">Saturação Oxigênio</th>
                  <th class="">Frequência respiratória</th>
                  <th class="">Pressão arterial</th>
                  <th class="">Observações</th>
                  <th class="">Atendido por</th>
                </tr>
              </thead>

              <tbody v-for="triagem in tempTriagens" :key="triagem.id">
                <tr>
                  <td>
                    {{ triagem.data }}
                  </td>
                  <td>
                    {{ triagem.temperatura }}
                  </td>
                  <td>
                    {{ triagem.peso }}
                  </td>

                  <td>
                    {{ triagem.altura }}
                  </td>

                  <td>
                    {{ triagem.frequencia_cardiaca }}
                  </td>

                  <td>
                    {{ triagem.saturacao_oxigenio }}
                  </td>

                  <td>
                    {{ triagem.frequencia_respiratoria }}
                  </td>

                  <td>
                    {{ triagem.pressao_arterial }}
                  </td>
                  <td>
                    {{ triagem.observacoes_triagem }}
                  </td>

                  <td>
                    <strong style="color: green">{{
                      triagem.atendido_por.nome
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="tempTriagens.length == 0"
            class="d-flex flex-column-fluid flex-center mt-12"
          >
            <a href="#" class="text-center mb-10">
              <img
                src="media/novaImagens/logo.png"
                class="max-h-200px"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>

    <!--begin::prontuario-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
            <h3 style="float: left" class="textTitile mt-4 mb-4">
              Dados da triagem
            </h3>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12 mb-4">
            <v-form lazy-validation>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    type="text"
                    v-model="frequencia_cardiaca"
                    label="Frequência cardíaca (Opcional)"
                  ></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-text-field
                    type="text"
                    v-model="temperatura"
                    label="Temperatura (Opcional)"
                  ></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-text-field
                    type="text"
                    v-model="peso"
                    label="Peso (Opcional)"
                  ></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-text-field
                    type="text"
                    v-model="altura"
                    label="Altura (Opcional)"
                  ></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <v-text-field
                    type="text"
                    v-model="saturacao_oxigenio"
                    label="Saturação de Oxigênio (Opcional)"
                  ></v-text-field>
                </div>

                <div class="col-md-4">
                  <v-text-field
                    type="text"
                    v-model="frequencia_respiratoria"
                    label="Frequência respiratória (Opcional)"
                  ></v-text-field>
                </div>

                <div class="col-md-4">
                  <div style="display: flex; flex-direction: row">
                    <v-text-field
                      type="text"
                      v-model="pressao_arterial"
                      label="Pressão arterial (Op)"
                    ></v-text-field>
                    por
                    <v-text-field
                      type="text"
                      v-model="pressaoResto"
                      label="Pressão arterial (Op)"
                    ></v-text-field>
                    mmHg
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-form-textarea
                    v-model="observacoes_triagem"
                    placeholder="Digite suas observações (Opcional)"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>

              <v-btn
                style="float: right"
                @click="editarDadosTriagem"
                class="mr-4 mt-4 textStyle"
                tile
                color="#1dd1a1"
              >
                <span style="color: white">Salvar</span>
              </v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "prontuario",
  components: {},
  props: {
    dados: Object,
  },

  async created() {
    try {
      const { data } = await ambulatorio.HistoricoTriagens(
        this.dados.dados_prontuario.id,
        1
      );

      this.triagens = data.historico_triagem;
      this.tempTriagens = data.historico_triagem;
      this.totalPages = data.total_pages;

      const response = await ambulatorio.triagemPorProntuário(
        this.dados.dados_prontuario.id
      );
      this.idTriagem = response.data.id;
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      pressaoResto: "",
      consultaDataUm: "",
      consultaDataDois: "",
      idTriagem: "",
      nomePaciente: "",
      frequencia_cardiaca: "",
      temperatura: "",
      peso: "",
      altura: "",
      saturacao_oxigenio: "",
      frequencia_respiratoria: "",
      pressao_arterial: "",
      observacoes_triagem: "",
      paciente_id: "",
      descricao: "",
      dataResultado: "",
      dataSolicitacao: "",
      totalPages: 1,
      page: 1,
      triagens: [],
      tempTriagens: [],
      consulta: "",
    };
  },

  methods: {
    editarDadosTriagem: async function () {
      const data = {
        triagem: {
          frequencia_cardiaca: this.frequencia_cardiaca,
          temperatura: this.temperatura,
          peso: this.peso,
          altura: this.altura,
          saturacao_oxigenio: this.saturacao_oxigenio,
          frequencia_respiratoria: this.frequencia_respiratoria,
          pressao_arterial: `${this.pressao_arterial} por ${this.pressaoResto} mmHg`,
          observacoes_triagem: this.observacoes_triagem,
        },
      };

      try {
        await ambulatorio.updateTriagem(this.idTriagem, data);

        Swal.fire({
          title: "",
          text: "Cadastro realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        const response = await ambulatorio.HistoricoTriagens(
          this.dados.dados_prontuario.id,
          1
        );

        this.triagens = response.data.historico_triagem;
        this.tempTriagens = response.data.historico_triagem;
        this.totalPages = response.data.total_pages;

        this.idTriagem = response.data.id;
        this.frequencia_cardiaca = "";
        this.temperatura = "";
        this.peso = "";
        this.altura = "";
        this.saturacao_oxigenio = "";
        this.frequencia_respiratoria = "";
        this.observacoes_triagem = "";
        this.pressao_arterial = "";
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    consultaResultadoTriagens: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.HistoricoTriagens(
          this.dados.dados_prontuario.id,
          1
        );

        this.triagens = data.historico_triagem;
        this.tempTriagens = data.historico_triagem;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            created_at: this.consulta,
          },
          required: {
            prontuario_id: this.dados.dados_prontuario.id,
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoTriagem(data);

        this.triagens = response.data.resultado;
        this.tempTriagens = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.HistoricoTriagens(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.triagens = response.data.historico_triagem;
          this.tempTriagens = response.data.historico_triagem;
          this.totalPages = response.data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.HistoricoTriagens(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.triagens = response.data.historico_triagem;
          this.tempTriagens = response.data.historico_triagem;
          this.totalPages = response.data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideModal() {
      this.$refs["filter-modal"].hide();
    },

    consultarPorIntervalo: async function () {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf,
          },
          required: {
            created_at: [this.consultaDataUm, this.consultaDataDois],
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoTriagem(data);

        this.triagens = response.data.resultado;
        this.tempTriagens = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário" }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}
</style>
