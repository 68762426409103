<template>
  <div>
    <!-- Tabs with card integration -->
    <b-card no-body header-bg-variant="success" header-text-variant="white">
      <template #header>
        <h4 class="mb-0">
          Nome completo: {{ dados.dados_paciente.pessoa.nome }}
        </h4>
        <h3 class="mb-0">CPF: {{ dados.dados_paciente.pessoa.cpf }}</h3>
        <h3 class="mb-0">Prontuário: {{ dados.dados_paciente.prontuario }}</h3>
      </template>
      <b-tabs fill justified tabs card>
        <b-tab title="Pessoais"
          ><ProntuarioDadosPessoais :dados="dados"
        /></b-tab>

        <b-tab title="Triagens"> <ProntuarioTriagem :dados="dados" /></b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Vacinas"
        >
          <ProntuarioVacina :dados="dados" />
        </b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Nascimento"
        >
          <ProntuarioNascimento :dados="dados" />
        </b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Pré-Natal"
        >
          <ProntuarioPreNatal :dados="dados" />
        </b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Consulta"
        >
          <ProntuarioConsulta :dados="dados" />
        </b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Exames"
        >
          <ProntuarioExame :dados="dados" />
        </b-tab>
        <b-tab
          v-if="
            perfil == 'Aluno' || perfil == 'Medico' || perfil == 'Administrador'
          "
          title="Procedimentos"
          ><ProntuarioProcedimentos :dados="dados" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ProntuarioDadosPessoais from "./ProntuarioDadosPessoais";
import ProntuarioTriagem from "./ProntuarioTriagem";
import ProntuarioExame from "./ProntuarioExames";
import ProntuarioVacina from "./ProntuarioVacina";
import ProntuarioNascimento from "./ProntuarioNascimento";
import ProntuarioPreNatal from "./ProntuarioPreNatal";
import ProntuarioConsulta from "./ProntuarioConsulta";
import ProntuarioProcedimentos from "./ProntuarioProcedimentos";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "prontuario",
  components: {
    ProntuarioDadosPessoais,
    ProntuarioTriagem,
    ProntuarioExame,
    ProntuarioVacina,
    ProntuarioNascimento,
    ProntuarioPreNatal,
    ProntuarioConsulta,
    ProntuarioProcedimentos,
  },
  created() {
    console.log("Dados recebidos para prontuário: ");
    console.log(this.$route.params.dados);
  },

  data() {
    return {
      perfil: localStorage.getItem("tipoUsuario"),
      dados: this.$route.params.dados,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Painel Prontuário" }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}
</style>
