var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"no-body":"","header-bg-variant":"success","header-text-variant":"white"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-0"},[_vm._v(" Nome completo: "+_vm._s(_vm.dados.dados_paciente.pessoa.nome)+" ")]),_c('h3',{staticClass:"mb-0"},[_vm._v("CPF: "+_vm._s(_vm.dados.dados_paciente.pessoa.cpf))]),_c('h3',{staticClass:"mb-0"},[_vm._v("Prontuário: "+_vm._s(_vm.dados.dados_paciente.prontuario))])]},proxy:true}])},[_c('b-tabs',{attrs:{"fill":"","justified":"","tabs":"","card":""}},[_c('b-tab',{attrs:{"title":"Pessoais"}},[_c('ProntuarioDadosPessoais',{attrs:{"dados":_vm.dados}})],1),_c('b-tab',{attrs:{"title":"Triagens"}},[_c('ProntuarioTriagem',{attrs:{"dados":_vm.dados}})],1),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Vacinas"}},[_c('ProntuarioVacina',{attrs:{"dados":_vm.dados}})],1):_vm._e(),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Nascimento"}},[_c('ProntuarioNascimento',{attrs:{"dados":_vm.dados}})],1):_vm._e(),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Pré-Natal"}},[_c('ProntuarioPreNatal',{attrs:{"dados":_vm.dados}})],1):_vm._e(),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Consulta"}},[_c('ProntuarioConsulta',{attrs:{"dados":_vm.dados}})],1):_vm._e(),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Exames"}},[_c('ProntuarioExame',{attrs:{"dados":_vm.dados}})],1):_vm._e(),(
          _vm.perfil == 'Aluno' || _vm.perfil == 'Medico' || _vm.perfil == 'Administrador'
        )?_c('b-tab',{attrs:{"title":"Procedimentos"}},[_c('ProntuarioProcedimentos',{attrs:{"dados":_vm.dados}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }