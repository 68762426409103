<template>
  <div>
    <b-modal
      ref="my-modal-nova-vacina"
      hide-footer
      centered
      id="modal-nova-vacina"
      title="Nova vacina"
    >
      <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarNovaVacinaTipo">
            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Tipo da vacina</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo da vacina"
                  rules="required"
                >
                  <b-form-select
                    v-model="tipoVacinaNova"
                    :error-messages="errors"
                    :options="optionsTipoVacinaNova"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-4">
                <h6 style="color: grey">Descrição</h6>
                <validation-provider>
                  <v-text-field v-model="descricaoNovaVacina"></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="cadastrarNovaVacinaTipo"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>
    <!--begin::prontuario-->

    <b-modal
      ref="my-modal"
      hide-footer
      centered
      id="modal-1"
      title="Cadastrar Vacina - Infatil"
    >
      <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarVacina">
            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Vacina (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Vacina"
                  rules="required"
                >
                  <b-form-select
                    v-model="selectedVacina"
                    :error-messages="errors"
                    :options="optionsInfatil"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h6 class="mt-4" style="color: grey">
                  Primeira dose (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Primeira dose"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="primeira_dose"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Segunda dose</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="segunda_dose"
                    color="red"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Terceira dose</h6>
                <validation-provider>
                  <v-text-field
                    v-model="terceira_dose"
                    type="Date"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Primeiro Reforço</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="primeiro_reforco"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Segundo Reforço</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="segundo_refonco"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="cadastrarVacina"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal
      ref="my-modal2"
      hide-footer
      centered
      id="modal-2"
      title="Cadastrar Vacina - Adulto"
    >
      <div>
        <validation-observer ref="observer2" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarVacina">
            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Vacina (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Vacina"
                  rules="required"
                >
                  <b-form-select
                    v-model="selectedVacina"
                    :error-messages="errors"
                    :options="optionsAdulto"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h6 class="mt-4" style="color: grey">
                  Primeira dose (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Primeira dose"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="primeira_dose"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Segunda dose</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="segunda_dose"
                    color="red"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Terceira dose</h6>
                <validation-provider>
                  <v-text-field
                    v-model="terceira_dose"
                    type="Date"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Primeiro Reforço</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="primeiro_reforco"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Segundo Reforço</h6>
                <validation-provider>
                  <v-text-field
                    type="Date"
                    v-model="segundo_refonco"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="cadastrarVacina"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>

    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: rgb(29, 209, 161);
            "
            class="col-md-12"
          >
            <h3 class="textTitile mt-4 mb-4">Infantil</h3>
            <div class="">
              <b-button
                variant="success"
                style="
                  font-size: 1.2rem;
                  margin-left: 1rem;
                  border-radius: 0px;
                  border: 2px solid white;
                  color: white;
                "
                v-b-modal.modal-nova-vacina
                >Nova vacina</b-button
              >
              <b-button
                variant="success"
                style="
                  font-size: 1.2rem;
                  margin-left: 0px;
                  border-radius: 0px;
                  border: 2px solid white;
                  color: white;
                "
                v-b-modal.modal-1
                >Cadastrar Vacina</b-button
              >
            </div>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-md-12">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="">Descrição</th>

                    <th class="">1º dose</th>
                    <th class="">2º dose</th>
                    <th class="">3º dose</th>
                    <th class="">1º reforço</th>
                    <th class="">2º reforço</th>
                    <th class="">Ações</th>
                  </tr>
                </thead>

                <tbody v-for="vacina in infantilVacinas" :key="vacina.id">
                  <tr>
                    <td>
                      <input
                        disabled
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.vacina.descricao"
                      />
                    </td>

                    <td>
                      <input
                        type="date"
                        :disabled="vacina.primeira_dose == null ? false : true"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.primeira_dose"
                        v-on:input="handlePrimeiraDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="vacina.segunda_dose == null ? false : true"
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.segunda_dose"
                        v-on:input="handleSegundaDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="vacina.terceira_dose == null ? false : true"
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.terceira_dose"
                        v-on:input="handleTerceiraDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="
                          vacina.primeiro_reforco == null ? false : true
                        "
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.primeiro_reforco"
                        v-on:input="handlePrimeiroReforco($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="
                          vacina.segundo_reforco == null ? false : true
                        "
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.segundo_reforco"
                        v-on:input="handleSegundoReforco($event)"
                      />
                    </td>

                    <td>
                      <b-tooltip
                        :target="`tooltip-target-${vacina.id}`"
                        triggers="hover"
                      >
                        Atualizar status do exame
                      </b-tooltip>

                      <v-btn
                        @click="updateVacina(vacina.id, vacina.vacina_id)"
                        :id="`tooltip-target-${vacina.id}`"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div
              v-if="infantilVacinas.length == 0"
              class="d-flex flex-column-fluid flex-center mt-12"
            >
              <a href="#" class="text-center mb-10">
                <img
                  src="media/novaImagens/logo.png"
                  class="max-h-200px"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div
            style="float: left; background-color: rgb(29, 209, 161)"
            class="col-md-12"
          >
            <h3 style="float: left" class="textTitile mt-4 mb-4">Adulto</h3>

            <div>
              <b-button
                class="mt-1"
                variant="success"
                style="
                  font-size: 1.2rem;
                  margin-left: 1rem;
                  border-radius: 0px;
                  border: 2px solid white;
                  color: white;
                "
                v-b-modal.modal-nova-vacina
                >Nova vacina</b-button
              >
              <b-button
                variant="success"
                style="
                  font-size: 1.2rem;
                  margin-left: 0px;
                  margin-top: 0.2rem;
                  border-radius: 0px;
                  border: 2px solid white;
                  color: white;
                "
                v-b-modal.modal-2
                >Cadastrar Vacina</b-button
              >
            </div>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-md-12">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="">Descrição</th>

                    <th class="">1º dose</th>
                    <th class="">2º dose</th>
                    <th class="">3º dose</th>
                    <th class="">1º reforço</th>
                    <th class="">2º reforço</th>
                    <th class="">Ações</th>
                  </tr>
                </thead>

                <tbody v-for="vacina in adultoVacinas" :key="vacina.id">
                  <tr>
                    <td>
                      <input
                        disabled
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.vacina.descricao"
                      />
                    </td>

                    <td>
                      <input
                        type="date"
                        :disabled="vacina.primeira_dose == null ? false : true"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.primeira_dose"
                        v-on:input="handlePrimeiraDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="vacina.segunda_dose == null ? false : true"
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.segunda_dose"
                        v-on:input="handleSegundaDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="vacina.terceira_dose == null ? false : true"
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.terceira_dose"
                        v-on:input="handleTerceiraDose($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="
                          vacina.primeiro_reforco == null ? false : true
                        "
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.primeiro_reforco"
                        v-on:input="handlePrimeiroReforco($event)"
                      />
                    </td>

                    <td>
                      <input
                        :disabled="
                          vacina.segundo_reforco == null ? false : true
                        "
                        type="date"
                        style="
                          border: none;
                          height: 3rem;
                          background-color: white;
                          border-radius: 5px;
                          padding: 1rem;
                        "
                        :value="vacina.segundo_reforco"
                        v-on:input="handleSegundoReforco($event)"
                      />
                    </td>

                    <td>
                      <b-tooltip
                        :target="`tooltip-target-${vacina.id}`"
                        triggers="hover"
                      >
                        Atualizar status do exame
                      </b-tooltip>

                      <v-btn
                        @click="updateVacina(vacina.id, vacina.vacina_id)"
                        :id="`tooltip-target-${vacina.id}`"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div
              v-if="adultoVacinas.length == 0"
              class="d-flex flex-column-fluid flex-center mt-12"
            >
              <a href="#" class="text-center mb-10">
                <img
                  src="media/novaImagens/logo.png"
                  class="max-h-200px"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});
export default {
  name: "ProntuarioVacina",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dados: Object,
  },

  data() {
    return {
      selectedVacina: null,
      errors: "",
      vacina: [],
      infantilVacinas: [],
      adultoVacinas: [],
      primeira_dose: null,
      segunda_dose: null,
      terceira_dose: null,
      primeiro_reforco: null,
      segundo_refonco: null,
      vacina_id: null,
      tipoVacinaNova: null,
      descricaoNovaVacina: "",
      optionsTipoVacinaNova: [
        { value: null, text: "Selecione um tipo de vacina" },
        { value: "Infantil", text: "Infantil" },
        { value: "Adulto", text: "Adulto" },
      ],
      optionsInfatil: [{ value: null, text: "Selecione uma vacina" }],
      optionsAdulto: [{ value: null, text: "Selecione uma vacina" }],
    };
  },

  async created() {
    try {
      const { data } = await ambulatorio.listDeTiposVacinas();

      this.listVacinas = data.vacina;

      this.listVacinas
        .filter((vacina) => vacina.tipo == "Infantil")
        .map((vacina) => {
          this.optionsInfatil = [
            ...this.optionsInfatil,
            { value: vacina.id, text: vacina.descricao },
          ];
        });

      this.listVacinas
        .filter((vacina) => vacina.tipo == "Adulto")
        .map((vacina) => {
          this.optionsAdulto = [
            ...this.optionsAdulto,
            { value: vacina.id, text: vacina.descricao },
          ];
        });

      // pegando informações da vacina infantil
      const { data: data2 } = await ambulatorio.listVacinasPaciente(
        this.dados.dados_prontuario.id,
        "infantil"
      );

      this.infantilVacinas = data2.vacina;

      // pegando informações da vacina adulta
      const { data: data3 } = await ambulatorio.listVacinasPaciente(
        this.dados.dados_prontuario.id,
        "adulto"
      );

      this.adultoVacinas = data3.vacina;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    updateVacina: async function (id, idVacina) {
      try {
        // puxa os dados atuais
        const { data } = await ambulatorio.showVacina(id);

        const data2 = {
          vacina_paciente: {
            primeira_dose:
              data.vacina_paciente.primeira_dose == null
                ? this.primeira_dose
                : data.vacina_paciente.primeira_dose,
            segunda_dose:
              data.vacina_paciente.segunda_dose == null
                ? this.segunda_dose
                : data.vacina_paciente.segunda_dose,
            terceira_dose:
              data.vacina_paciente.terceira_dose == null
                ? this.terceira_dose
                : data.vacina_paciente.terceira_dose,
            primeiro_reforco:
              data.vacina_paciente.primeira_reforco == null
                ? this.primeira_reforco
                : data.vacina_paciente.primeira_reforco,
            segundo_refonco:
              data.vacina_paciente.segundo_refonco == null
                ? this.segundo_refonco
                : data.vacina_paciente.segundo_refonco,
            vacina_id: idVacina,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        await ambulatorio.updateVacina(id, data2);

        Swal.fire({
          title: "",
          text: "Vacina atualizada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        // pegando informações da vacina infantil
        const { data: dataIfantis } = await ambulatorio.listVacinasPaciente(
          this.dados.dados_prontuario.id,
          "infantil"
        );

        this.infantilVacinas = dataIfantis.vacina;

        // pegando informações da vacina adulta
        const { data: dataAdultas } = await ambulatorio.listVacinasPaciente(
          this.dados.dados_prontuario.id,
          "adulto"
        );

        this.adultoVacinas = dataAdultas.vacina;
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    handlePrimeiraDose: function (e) {
      this.primeira_dose = e.target.value;
    },

    handleSegundaDose: function (e) {
      this.segunda_dose = e.target.value;
    },

    handleTerceiraDose: function (e) {
      this.terceira_dose = e.target.value;
    },

    handlePrimeiroReforco: function (e) {
      this.primeiro_reforco = e.target.value;
    },

    handleSegundoReforco: function (e) {
      this.segundo_refonco = e.target.value;
    },

    cadastrarNovaVacinaTipo: async function () {
      try {
        const data1 = {
          vacina: {
            descricao: this.descricaoNovaVacina,
            tipo: this.tipoVacinaNova,
          },
        };

        await ambulatorio.adicionarNovaVacina(data1);

        const { data } = await ambulatorio.listDeTiposVacinas();

        this.listVacinas = data.vacina;

        this.listVacinas
          .filter((vacina) => vacina.tipo == "Infantil")
          .map((vacina) => {
            this.optionsInfatil = [
              ...this.optionsInfatil,
              { value: vacina.id, text: vacina.descricao },
            ];
          });

        this.listVacinas
          .filter((vacina) => vacina.tipo == "Adulto")
          .map((vacina) => {
            this.optionsAdulto = [
              ...this.optionsAdulto,
              { value: vacina.id, text: vacina.descricao },
            ];
          });

        this.hideModal();

        Swal.fire({
          title: "",
          text: "Vacina adicionada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao adicionar vacina, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    cadastrarVacina: async function () {
      try {
        const data = {
          vacina_paciente: {
            primeira_dose: this.primeira_dose,
            segunda_dose: this.segunda_dose,
            terceira_dose: this.terceira_dose,
            primeiro_reforco: this.primeiro_reforco,
            segundo_refonco: this.segundo_refonco,
            vacina_id: this.selectedVacina,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        console.log(data);

        await ambulatorio.registerVacinaPaciente(data);

        // Atualizando informações da vacina
        // pegando informações da vacina infantil
        const { data: data2 } = await ambulatorio.listVacinasPaciente(
          this.dados.dados_prontuario.id,
          "infantil"
        );

        this.infantilVacinas = data2.vacina;

        // pegando informações da vacina adulta
        const { data: data3 } = await ambulatorio.listVacinasPaciente(
          this.dados.dados_prontuario.id,
          "adulto"
        );

        this.adultoVacinas = data3.vacina;

        this.hideModal();
        this.hideModal2();

        Swal.fire({
          title: "",
          text: "Vacina cadastrada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Vacina já cadastrada!, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    showModal() {
      this.$refs["my-modal"].show();
      this.$refs["my-modal2"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs["my-modal2"].hide();
      this.$refs["my-modal-nova-vacina"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
      this.$refs["my-modal2"].toggle("#toggle-btn");
    },

    showModal2() {
      this.$refs["my-modal2"].show();
    },
    hideModal2() {
      this.$refs["my-modal2"].hide();
    },
    toggleModal2() {
      this.$refs["my-modal2"].toggle("#toggle-btn");
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário " }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}
</style>
