<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-form lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="nomePaciente"
                label="Nome completo"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field
                disabled
                type="text"
                v-model="numeroProntuario"
                label="Prontuário"
                required
              ></v-text-field>
            </div>

            <div class="col-md-6">
              <v-text-field
                disabled
                type="text"
                v-model="cpfPaciente"
                label="CPF"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field
                disabled
                type="text"
                v-model="pacienteTelefone"
                label="Telefone"
                required
              ></v-text-field>
            </div>

            <div class="col-md-6">
              <v-text-field
                disabled
                type="text"
                v-model="especialidadeConsulta"
                label="Especialidade"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="atendidoPor"
                label="Atendido por"
                required
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-form lazy-validation>
          <div class="row">
            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_cardiaca"
                label="Frequência cardíaca"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="temperatura"
                label="Temperatura"
                required
              ></v-text-field>
            </div>

            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="peso"
                label="Peso"
                required
              ></v-text-field>
            </div>

            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="altura"
                label="Altura"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="saturacao_oxigenio"
                label="Saturação de Oxigênio"
                required
              ></v-text-field>
            </div>

            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_respiratoria"
                label="Frequência respiratória"
                required
              ></v-text-field>
            </div>

            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="pressao_arterial"
                label="Pressão arterial"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-form-textarea
                disabled
                v-model="observacoes_triagem"
                placeholder="Digite suas observações"
                rows="3"
                required
                max-rows="6"
                label="Observações"
              ></b-form-textarea>
            </div>
          </div>
        </v-form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-button
          @click="gerarPdf"
          variant="success"
          v-b-tooltip.hover.top="'Realizar download das informações'"
          color="#1dd1a1"
          class="mr-2 mb-2 mt-6"
        >
          Baixar dados
          <v-icon class="ml-2" center small color="#FFF">
            fas fa-file-download</v-icon
          ></b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as ambulatorio from "../../../../core/services/ambulatorioAPI.service";
import jsPDF from "jspdf";
export default {
  name: "visualizarDadosTriagem",
  props: {
    idConsulta: Number,
  },
  async created() {
    try {
      const { data } = await ambulatorio.prontuarioConsultaShowTriagem(
        this.idConsulta
      );

      this.nomePaciente = data.historico_consulta.dados_paciente.pessoa.nome;
      this.cpfPaciente = data.historico_consulta.dados_paciente.pessoa.cpf;
      this.pacienteTelefone = data.historico_consulta.dados_paciente.telefone;
      this.especialidadeConsulta =
        data.historico_consulta.especialidade.descricao;
      this.atendidoPor = data.historico_consulta.medico_nome;
      this.numeroProntuario = data.historico_consulta.prontuario.numero;
      //dados de triagem
      this.frequencia_cardiaca = data.historico_triagem.frequencia_cardiaca;
      this.temperatura = data.historico_triagem.temperatura;
      this.peso = data.historico_triagem.peso;
      this.altura = data.historico_triagem.altura;
      this.saturacao_oxigenio = data.historico_triagem.saturacao_oxigenio;
      this.frequencia_respiratoria =
        data.historico_triagem.frequencia_respiratoria;
      this.observacoes_triagem = data.historico_triagem.observacoes_triagem;
      this.pressao_arterial = data.historico_triagem.pressao_arterial;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    gerarPdf: function () {
      const doc = new jsPDF();

      doc.text(`Dados da consulta`, 80, 55);
      doc.line(30, 60, 185, 60);
      doc.addImage("media/novaImagens/ambufersa02.png", "PNG", 30, 10, 150, 40);
      doc.setFontSize(15);
      doc.text(`Nome completo: ${this.nomePaciente} `, 15, 75);

      doc.text(`CPF: ${this.cpfPaciente} `, 15, 85);
      doc.text(`Telefone: ${this.pacienteTelefone} `, 120, 85);

      doc.text(`Especialidade: ${this.especialidadeConsulta} `, 15, 95);
      doc.text(`Prontuário: ${this.numeroProntuario} `, 120, 95);
      doc.text(`Atendido por: ${this.atendidoPor} `, 15, 105);

      doc.text(`Dados de triagem`, 80, 120);
      doc.line(30, 125, 185, 125);

      // dados de triagem
      doc.text(`Frequência cardíaca: ${this.frequencia_cardiaca} `, 15, 135);
      doc.text(`Temperatura: ${this.temperatura} `, 120, 135);

      doc.text(`Peso: ${this.peso} `, 15, 145);
      doc.text(`Altura: ${this.altura} `, 120, 145);

      doc.text(`Saturação de Oxigênio: ${this.saturacao_oxigenio} `, 15, 155);
      doc.text(
        `Frequência respiratória: ${this.frequencia_respiratoria} `,
        120,
        155
      );

      doc.text(`Pressão arterial: ${this.pressao_arterial} `, 15, 165);

      // salvar documento

      doc.save(`Ficha - ${this.nomePaciente} - ${this.numeroProntuario} .pdf`);
    },
  },
  data() {
    return {
      pacienteTelefone: "",
      nomePaciente: "",
      cpfPaciente: "",
      especialidadeConsulta: "",
      atendidoPor: "",
      numeroProntuario: "",
      frequencia_cardiaca: "",
      temperatura: "",
      peso: "",
      altura: "",
      saturacao_oxigenio: "",
      frequencia_respiratoria: "",
      pressao_arterial: "",
      observacoes_triagem: "",
      paciente_id: "",
    };
  },
};
</script>
