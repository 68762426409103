<template>
  <div>
    <HistoricoConsultas :dados="this.dados" />
    <!--begin::prontuario-->

    <div class="mb-6">
      <!--begin::Dashboard-->
      <div class="row">
        <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
          <h3 style="float: left" class="textTitile mt-4 mb-4">
            História do paciente
          </h3>
        </div>
        <div class="col-md-12 mt-4">
          <v-toolbar style="height: 6rem; display: none">
            <v-toolbar-title
              ><v-btn
                @click="consultarHistoriaPaciente"
                color="#1dd1a1"
                class="mr-4 mb-2 mt-6"
              >
                <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
              ></v-toolbar-title
            >

            <v-text-field
              @keyup.enter.native="consultarHistoriaPaciente"
              v-model="consulta"
              class="mt-14"
              label="Pesquise consultas por Data, CPF, especialidade"
              color="green"
              filled
              solo-inverted
            ></v-text-field>
          </v-toolbar>

          <div class="row mt-6">
            <div
              style="
                display: flex;
                align-itens: center;
                justify-content: space-between;
              "
              class="col-md-12"
            >
              <b-tooltip :target="`tooltip3-target`" triggers="hover">
                Página anterior
              </b-tooltip>
              <b-tooltip :target="`tooltip4-target`" triggers="hover">
                Próxima página
              </b-tooltip>
              <div class="mt-2">
                <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
              </div>
              <div>
                <v-btn
                  :disabled="this.page == 0 || this.page == 1 ? true : false"
                  @click="anteriorPage"
                  id="tooltip3-target"
                  color="#1dd1a1"
                  class="mr-1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-left</v-icon
                  ></v-btn
                >
                <v-btn
                  :disabled="
                    this.page == this.totalPages || this.totalPages == 0
                      ? true
                      : false
                  "
                  @click="proximoPage"
                  id="tooltip4-target"
                  color="#1dd1a1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-right</v-icon
                  ></v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Descrição da história</th>
                  <th class=""></th>
                  <th class=""></th>
                  <th class=""></th>
                  <th class="">Atendido por</th>
                </tr>
              </thead>

              <tbody v-for="historia in tempHistorias" :key="historia.id">
                <tr>
                  <td colspan="4">
                    {{ historia.historia }}
                  </td>

                  <td>
                    <strong style="color: green">{{
                      historia.atendido_por.nome
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="historia == 0"
            class="d-flex flex-column-fluid flex-center mt-12"
          >
            <a href="#" class="text-center mb-10">
              <img
                src="media/novaImagens/logo.png"
                class="max-h-200px"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>

    <div class="row">
      <div class="col-md-12">
        <!--begin::prontuario-->
        <div class="row">
          <div class="col-md-12">
            <div
              style="
                background-color: rgb(29, 209, 161);
                height: 3.8rem;
                padding: 0.5px;
              "
              class="col-md-12"
            >
              <h3 style="float: left" class="textTitile mt-4 ml-4">
                Dados de História do paciente
              </h3>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <validation-observer
              class="mt-6"
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="cadastrarHistoriaPaciente">
                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Descrição"
                      rules="required"
                    >
                      <b-form-textarea
                        :error-messages="errors"
                        v-model="descricaoHistoria"
                        placeholder="Digite a descrição da História"
                        rows="3"
                        required
                        max-rows="6"
                      ></b-form-textarea>
                    </validation-provider>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <v-btn
                      v-if="
                        dados.dados_prontuario.historia_paciente_existe == false
                      "
                      @click="cadastrarHistoriaPaciente"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>

                    <v-btn
                      v-else
                      @click="atualizarHistoriaPaciente"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
        <!--end::prontuario-->
      </div>
    </div>

    <HistoriaPatologica :dados="this.dados" />
    <HistoriaFamiliar :dados="this.dados" />
    <HistoriaHabitos :dados="this.dados" />

    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import HistoricoConsultas from "./componentes/HistoricoConsulta";
import HistoriaPatologica from "./componentes/HistoriaPatologica";
import HistoriaFamiliar from "./componentes/HistoriaFamiliar";
import HistoriaHabitos from "./componentes/HistoriaHabitos";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});

export default {
  name: "prontuario",
  components: {
    HistoriaHabitos,
    HistoriaFamiliar,
    HistoriaPatologica,
    HistoricoConsultas,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dados: Object,
  },
  async created() {
    try {
      const { data: data2 } = await ambulatorio.showProntuarioHistoriaPaciente(
        this.dados.dados_prontuario.id
      );

      this.idHistoriaPaciente = data2.historia_paciente.id;

      const { data } = await ambulatorio.listaHistoriaPaciente(
        this.dados.dados_prontuario.id,
        1
      );

      this.totalPages = data.total_pages;
      this.historias = data.historico_historia_paciente;
      this.tempHistorias = data.historico_historia_paciente;
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      idHistoriaPaciente: "",
      descricaoHistoria: "",
      consulta: "",
      historias: [],
      tempHistorias: [],
      totalPages: "",
      page: 1,

      paciente_id: "",
    };
  },

  methods: {
    atualizarHistoriaPaciente: async function () {
      try {
        const data = {
          historia_paciente: {
            historia: this.descricaoHistoria,
          },
        };

        await ambulatorio.atualizarHistoriaPaciente(
          this.idHistoriaPaciente,
          data
        );

        Swal.fire({
          title: "",
          text: "Cadastro realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        this.descricaoHistoria = "";

        const { data: data2 } = await ambulatorio.listaHistoriaPaciente(
          this.dados.dados_prontuario.id,
          1
        );

        this.totalPages = data2.total_pages;
        this.historias = data2.historico_historia_paciente;
        this.tempHistorias = data2.historico_historia_paciente;
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    cadastrarHistoriaPaciente: async function () {
      try {
        const data = {
          historia_paciente: {
            historia: this.descricaoHistoria,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        await ambulatorio.registerHistoriaPaciente(data);

        Swal.fire({
          title: "",
          text: "Cadastro realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        const { data: data2 } = await ambulatorio.listaHistoriaPaciente(
          this.dados.dados_prontuario.id,
          1
        );

        this.totalPages = data2.total_pages;
        this.historias = data2.historico_historia_paciente;
        this.tempHistorias = data2.historico_historia_paciente;

        const { data: data3 } =
          await ambulatorio.showProntuarioHistoriaPaciente(
            this.dados.dados_prontuario.id
          );

        this.idHistoriaPaciente = data3.historia_paciente.id;

        this.descricaoHistoria = "";

        this.dados.dados_prontuario.historia_paciente_existe = true;
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    consultarHistoriaPaciente: async function () {},
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const { data } = await ambulatorio.listaHistoriaPaciente(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.totalPages = data.total_pages;
          this.historias = data.historico_historia_paciente;
          this.tempHistorias = data.historico_historia_paciente;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const { data } = await ambulatorio.listaHistoriaPaciente(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.totalPages = data.total_pages;
          this.historias = data.historico_historia_paciente;
          this.tempHistorias = data.historico_historia_paciente;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário" }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}
</style>
