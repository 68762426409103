<template>
  <div>
    <b-modal
      ref="filter-modal"
      hide-footer
      centered
      id="modal-dataNascimento"
      title="Filtrar nascimentos por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <!--begin::prontuario-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div class="col-md-12">
            <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
              <h3
                style="text-align: left; padding: 1rem"
                class="textTitile mt-4 mb-4"
              >
                Dados de nascimento
              </h3>
            </div>

            <validation-observer
              class="mt-6"
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="atualizarPaciente">
                <div class="row">
                  <div class="col-md-3 mt-4">
                    <validation-provider name="Estado civil">
                      <b-form-select
                        style="border-color: black"
                        v-model="tipoParto"
                        :options="options"
                      ></b-form-select>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider name="Apgar">
                      <v-text-field
                        v-model="apgar"
                        label="Apgar "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider name="Perímetro cefálico ">
                      <v-text-field
                        v-model="perimetroCefalico"
                        label="Perímetro cefálico "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <validation-provider name="Intercorrẽncia">
                      <b-form-select
                        style="border-color: black"
                        v-model="intercorrencia"
                        :options="options2"
                        required
                      ></b-form-select>
                    </validation-provider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <validation-provider name="Peso ao nascer">
                      <v-text-field
                        v-model="pesoNascer"
                        label="Peso ao nascer "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider name="Peso de alta">
                      <v-text-field
                        v-model="pesoAlta"
                        label="Peso de alta "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider name="Comprimento">
                      <v-text-field
                        v-model="comprimento"
                        label="Comprimento "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-3">
                    <validation-provider name="Data de alta">
                      <v-text-field
                        type="date"
                        v-model="dataAlta"
                        label="Data de alta "
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <v-btn
                      v-if="dados.dados_prontuario.nascimento_existe == false"
                      @click="createNascimento"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>

                    <v-btn
                      v-else
                      @click="updateNascimento"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-6">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div class="col-md-12">
            <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
              <h3
                style="text-align: left; padding: 1rem"
                class="textTitile mt-4 mb-4"
              >
                Testes
              </h3>
            </div>

            <validation-observer
              class="mt-6"
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="createTestes">
                <div class="row">
                  <div class="col-md-6 mt-4">
                    <b-card title="Pezinho">
                      <b-card-text>
                        <validation-provider name="Pezinho">
                          <label style="font-size: 1.2rem" for="Fenilcetonúria"
                            >Fenilcetonúria</label
                          >
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              multiple
                              v-model="fenilTeste"
                              :options="optionsTestes"
                              name="Fenilcetonúria"
                            ></b-form-radio-group>
                          </b-form-group>

                          <label style="font-size: 1.2rem" for="Hipotireodismo"
                            >Hipotireodismo</label
                          >
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              v-model="hipoTeste"
                              :options="optionsTestes"
                              name="Hipotireodismo"
                            ></b-form-radio-group>
                          </b-form-group>
                          <label
                            style="font-size: 1.2rem"
                            for="Anemia Falciforme"
                            >Anemia Falciforme</label
                          >
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              v-model="anemiaTeste"
                              :options="optionsTestes"
                              name="Anemia Falciforme"
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-card-text>
                    </b-card>
                  </div>

                  <div class="col-md-6 mt-4">
                    <b-card title="Orelhinha">
                      <b-card-text>
                        <validation-provider name="Orelhinha">
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              multiple
                              v-model="eoaOuPeate"
                              :options="optionsTeste2"
                            ></b-form-radio-group>
                          </b-form-group>

                          <label style="font-size: 1.2rem" for="OD">OD</label>
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              v-model="odTeste"
                              :options="optionsTestes"
                              name="OD"
                            ></b-form-radio-group>
                          </b-form-group>
                          <label style="font-size: 1.2rem" for="OE">OE</label>
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              v-model="oeTeste"
                              :options="optionsTestes"
                              name="OE"
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mt-4">
                    <b-card title="Coraçãozinho">
                      <b-card-text>
                        <validation-provider name="Coraçãozinho">
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              multiple
                              v-model="coracaoTeste"
                              :options="optionsTestes"
                              name="coracao"
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-card-text>
                    </b-card>
                  </div>

                  <div class="col-md-6 mt-4">
                    <b-card title="Olhinho">
                      <b-card-text>
                        <validation-provider name="Olhinho">
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              multiple
                              v-model="olhoTeste"
                              :options="optionsTestes"
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-card-text>
                    </b-card>
                  </div>

                  <div class="col-md-6 mt-4">
                    <b-card title="Linguinha">
                      <b-card-text>
                        <validation-provider name="Linguinha">
                          <b-form-group>
                            <b-form-radio-group
                              size="lg"
                              multiple
                              v-model="linguaTeste"
                              :options="optionsTestes"
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <v-btn
                      v-if="
                        dados.dados_prontuario.teste_nascimento_existe == false
                      "
                      @click="createTestes"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Cadastrar</span>
                    </v-btn>

                    <v-btn
                      v-else
                      @click="updateTestes"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});
export default {
  name: "prontuario",
  components: { ValidationProvider, ValidationObserver },
  props: {
    dados: Object
  },
  async created() {
    this.loadNascimento();

    const { data: teste } = await ambulatorio.getTestesNascimentos(
      this.dados.dados_prontuario.id
    );

    this.fenilTeste = teste[0].resultado;
    this.fenilTesteId = teste[0].id;

    this.hipoTeste = teste[1].resultado;
    this.hipoTesteId = teste[1].id;

    this.anemiaTeste = teste[2].resultado;
    this.anemiaTesteId = teste[2].id;

    this.odTeste = teste[3].resultado;
    this.odTesteId = teste[3].id;

    this.oeTeste = teste[4].resultado;
    this.oeTesteId = teste[4].id;

    this.coracaoTeste = teste[5].resultado;
    this.coracaoTesteId = teste[5].id;

    this.olhoTeste = teste[6].resultado;
    this.olhoTesteId = teste[6].id;

    this.linguaTeste = teste[7].resultado;
    this.linguaTesteId = teste[7].id;
  },
  data() {
    return {
      linguaTeste: "",
      linguaTesteId: "",
      olhoTeste: "",
      olhoTesteId: "",
      coracaoTeste: "",
      coracaoTesteId: "",
      odTeste: "",
      odTesteId: "",
      oeTeste: "",
      oeTesteId: "",
      anemiaTeste: "",
      anemiaTesteId: "",
      hipoTeste: "",
      hipoTesteId: "",
      fenilTeste: "",
      fenilTesteId: "",
      pezinho: "",
      pezinhoId: "",
      optionsTeste2: [
        { value: "PEATE", text: "PEATE" },
        { value: "EOA", text: "EOA" }
      ],
      optionsTestes: [
        { value: "Normal", text: "Normal" },
        { value: "Alterado", text: "Alterado" }
      ],
      consultaDataUm: "",
      consultaDataDois: "",
      idNascimento: null,
      tipoParto: null,
      intercorrencia: null,
      perimetroCefalico: "",
      apgar: "",
      pesoNascer: "",
      pesoAlta: "",
      comprimento: "",
      dataAlta: "",
      options: [
        { value: null, text: "Seleciona o tipo de parto " },
        { value: "Normal", text: "Normal" },
        { value: "Cesárea", text: "Cesárea" }
      ],
      options2: [
        { value: null, text: "Selecione a Intercorrência " },
        { value: 1, text: "Não" },
        { value: 2, text: "Sim" },
        { value: 3, text: "Hipóxia" },
        { value: 4, text: "Outra complicação" }
      ],
      totalPages: 1,
      page: 1,
      nascimentos: [],
      tempNascimentos: [],
      consulta: ""
    };
  },

  methods: {
    createTestes: async function() {
      try {
        const fenil = {
          teste_nascimento: {
            nome: "Fenilcetonúria",
            resultado: this.fenilTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(fenil);

        const hipo = {
          teste_nascimento: {
            nome: "Hipotireodismo",
            resultado: this.hipoTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(hipo);

        const anemia = {
          teste_nascimento: {
            nome: "Anemia Falciforme",
            resultado: this.anemiaTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(anemia);

        const od = {
          teste_nascimento: {
            nome: "OD",
            resultado: this.odTeste,
            categoria: "Orelhinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(od);

        const oe = {
          teste_nascimento: {
            nome: "OE",
            resultado: this.oeTeste,
            categoria: "Orelhinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(oe);

        const coracaozinho = {
          teste_nascimento: {
            nome: "Coraçãozinho",
            resultado: this.coracaoTeste,
            categoria: "Coraçãozinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(coracaozinho);

        const olhinho = {
          teste_nascimento: {
            nome: "Olhinho",
            resultado: this.olhoTeste,
            categoria: "Olhinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(olhinho);

        const liguinha = {
          teste_nascimento: {
            nome: "Linguinha",
            resultado: this.linguaTeste,
            categoria: "Linguinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createTestesNascimentos(liguinha);

        Swal.fire({
          title: "",
          text: "Informações de teste salvas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro de testes, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },

    updateTestes: async function() {
      try {
        const fenil = {
          teste_nascimento: {
            nome: "Fenilcetonúria",
            resultado: this.fenilTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.fenilTesteId, fenil);

        const hipo = {
          teste_nascimento: {
            nome: "Hipotireodismo",
            resultado: this.hipoTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.hipoTesteId, hipo);

        const anemia = {
          teste_nascimento: {
            nome: "Anemia Falciforme",
            resultado: this.anemiaTeste,
            categoria: "Pezinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.anemiaTesteId, anemia);

        const od = {
          teste_nascimento: {
            nome: "OD",
            resultado: this.odTeste,
            categoria: "Orelhinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.odTesteId, od);

        const oe = {
          teste_nascimento: {
            nome: "OE",
            resultado: this.oeTeste,
            categoria: "Orelhinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.oeTesteId, oe);

        const coracaozinho = {
          teste_nascimento: {
            nome: "Coraçãozinho",
            resultado: this.coracaoTeste,
            categoria: "Coraçãozinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(
          this.coracaoTesteId,
          coracaozinho
        );

        const olhinho = {
          teste_nascimento: {
            nome: "Olhinho",
            resultado: this.olhoTeste,
            categoria: "Olhinho",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.olhoTesteId, olhinho);

        const liguinha = {
          teste_nascimento: {
            nome: "Linguinha",
            resultado: this.linguaTeste,
            categoria: "Linguinha",
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateTestesNascimentos(this.linguaTesteId, liguinha);

        Swal.fire({
          title: "",
          text: "Informações de teste salvas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro de testes, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    createNascimento: async function() {
      try {
        const data = {
          nascimento: {
            tipo_parto: this.tipoParto,
            apgar: this.apgar,
            intecorrencia: this.intercorrencia,
            peso_nascer: this.pesoNascer,
            peso_alta: this.pesoAlta,
            comprimento: this.comprimento,
            perimetro_cefalico: this.perimetroCefalico,
            data_alta: this.dataAlta,
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createNascimento(data);

        Swal.fire({
          title: "",
          text: "Informações de nascimento salvas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar salvamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    updateNascimento: async function() {
      try {
        const data = {
          nascimento: {
            tipo_parto: this.tipoParto,
            apgar: this.apgar,
            intecorrencia: this.intercorrencia,
            peso_nascer: this.pesoNascer,
            peso_alta: this.pesoAlta,
            comprimento: this.comprimento,
            perimetro_cefalico: this.perimetroCefalico,
            data_alta: this.dataAlta,
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updateNascimento(this.idNascimento, data);

        Swal.fire({
          title: "",
          text: "Informações de nascimento atualizadas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    loadNascimento: async function() {
      try {
        const { data } = await ambulatorio.getByProntuarioNascimento(
          this.dados.dados_prontuario.id
        );

        this.tipoParto = data.nascimento.tipo_parto;
        this.intercorrencia = data.nascimento.intecorrencia;
        this.perimetroCefalico = data.nascimento.perimetro_cefalico;
        this.apgar = data.nascimento.apgar;
        this.pesoNascer = data.nascimento.peso_nascer;
        this.pesoAlta = data.nascimento.peso_alta;
        this.comprimento = data.nascimento.comprimento;
        this.dataAlta = data.nascimento.data_alta;
        this.idNascimento = data.nascimento.id;

        console.log("INFORMAÇÕES NASCIMENTOoooooooooooooooooooo");
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    consultaNascimentoResultado: async function() {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.historicoNascimentos(
          this.dados.dados_prontuario.id,
          1
        );

        this.nascimentos = data.histórico_nascimento;
        this.tempNascimentos = data.histórico_nascimento;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            created_at: this.consulta
          },
          required: {
            prontuario_id: this.dados.dados_prontuario.id
          },
          page: 1,
          size: 10
        };

        const response = await ambulatorio.buscaResultadoNascimento(data);

        this.nascimentos = response.data.resultado;
        this.tempNascimentos = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },
    proximoPage: async function() {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.historicoNascimentos(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.nascimentos = response.data.histórico_nascimento;
          this.tempNascimentos = response.data.histórico_nascimento;
          this.totalPages = response.data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function() {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.historicoNascimentos(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.nascimentos = response.data.histórico_nascimento;
          this.tempNascimentos = response.data.histórico_nascimento;
          this.totalPages = response.data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideModal() {
      this.$refs["filter-modal"].hide();
    },

    consultarPorIntervalo: async function() {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf
          },
          required: {
            created_at: [this.consultaDataUm, this.consultaDataDois]
          },
          page: 1,
          size: 10
        };

        const response = await ambulatorio.buscaResultadoNascimento(data);

        this.nascimentos = response.data.resultado;
        this.tempNascimentos = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal();
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário " }]);
  }
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}

.theme--light.v-messages {
  color: red !important ;
}
</style>
